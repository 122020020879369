export const url = 'https://api.fastpix.io';

export const fetchHeaders = (token) =>
{
  let returnHeader;
  if (token && token !== '')
  {
    returnHeader = {
      accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`
    }
  } else
  {
    returnHeader = {
      accept: "application/json",
      "Content-Type": "application/json",
    }
  }

  return returnHeader;
}

export const Post = async (endpoint, authToken, data) =>
{
  const newData = new Promise((resolve, reject) =>
  {
    fetch(`${url}${endpoint}`, {
      method: 'Post',
      headers: fetchHeaders(authToken),
      body: JSON.stringify(data),
    }).then((res) =>
    {
      if (res.status === 200)
      {
        resolve(res.json())
      } else
      {
        reject(res)
      }
    })
  });

  return newData;
}

export const Get = async (endpoint, authToken) =>
{
  const newData = new Promise((resolve, reject) =>
  {
    fetch(`${url}${endpoint}`, {
      method: 'Get',
      headers: fetchHeaders(authToken),
    }).then((res) =>
    {
      if (res.status === 200)
      {
        resolve(res.json())
      } else
      {
        reject(res)
      }
    })
  });

  return newData;
}

export const Put = async (endpoint, authToken, data) =>
{
  const newData = new Promise((resolve, reject) =>
  {
    fetch(`${url}${endpoint}`, {
      method: 'Put',
      headers: fetchHeaders(authToken),
      body: JSON.stringify(data),
    }).then((res) =>
    {
      if (res.status === 200)
      {
        resolve(res.json())
      } else
      {
        reject(res)
      }
    })
  });

  return newData;
}

export const Delete = async (endpoint, authToken, data) =>
{
  const newData = new Promise((resolve, reject) =>
  {
    fetch(`${url}${endpoint}`, {
      method: 'Delete',
      headers: fetchHeaders(authToken),
      body: JSON.stringify(data),
    }).then((res) =>
    {
      if (res.status === 200)
      {
        resolve(res.json())
      } else
      {
        /* notification.error({
          message: `Code: ${res.status}`,
          description: 'Required fields are incorrect/missing'
        }) */
        reject(res)
      }
    })
  });

  return newData;
}