import { green } from "@ant-design/colors";
import { CheckOutlined } from "@ant-design/icons";
import { CheckCircleFilled } from "@ant-design/icons/lib/icons";
import { Button, Card, Col, Divider, Form, Input, List, Row, Typography } from "antd";
import { useState } from "react";

const MyProfile = () =>
{

  const { Title, Text } = Typography;

  const [myProfileForm] = Form.useForm();
  const [organizationsForm] = Form.useForm();

  const [togglePasswordChange, setTogglePasswordChange] = useState(false);

  const profileOrgs = ['Organization 1', 'Organization 2']

  return (
    <div className="main-content">
      <Card>
        <Row>
          <Col span={12}>
            <Title level={2}>My Profile</Title>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>

          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              form={myProfileForm}
            >
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item label="First Name">
                    <Input placeholder="Enter first name..." />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name">
                    <Input placeholder="Enter last name..." />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Email" rules={[
                    {
                      type: 'email',
                    },
                  ]}>
                    <Input disabled value="abcd@abcd.com" placeholder="Enter email..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col offset={12} span={12} style={{ display: 'flex', alignContent: 'end', justifyContent: 'flex-end' }}>
                  <Button type="primary" size="large">
                    Save Changes
                  </Button>
                </Col>
              </Row>

            </Form>
          </Col>
          <Divider />
          <Col span={24}>
            <Title level={5}>Organizations</Title>
          </Col>
          <Col span={24}>
            <List
              bordered
              dataSource={profileOrgs}
              renderItem={(item) => (
                <List.Item>
                  <Button type="link" style={{ textAlign: 'left' }} className="w-100">
                    <span style={{ color: green.primary, marginRight: '10px' }}>
                      <CheckCircleFilled color={green.primary} />
                    </span>
                    {item}</Button>
                </List.Item>
              )}
            />
          </Col>
          <Divider />
          <Col span={24}>
            <Title level={5}>Manage Your Account</Title>
          </Col>

          {
            togglePasswordChange ? (
              <Col span={12} style={{ marginTop: '30px' }}>
                <Form
                  layout="vertical"
                  form={myProfileForm}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item label="First Name">
                        <Input.Password placeholder="Enter current password..." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="New Password">
                        <Input.Password placeholder="Enter new password..." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Retype New Password">
                        <Input.Password placeholder="Retype new password..." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={12} span={12} style={{ display: 'flex', alignContent: 'end', justifyContent: 'flex-end' }}>
                      <Button size="large" style={{ marginRight: '15px' }} onClick={(e) => setTogglePasswordChange(false)}>
                        Cancel
                      </Button>
                      <Button type="primary" size="large" onClick={(e) => setTogglePasswordChange(false)}>
                        Change Password
                      </Button>
                    </Col>
                  </Row>

                </Form>
              </Col>
            ) : (
              <Col span={24}>
                <Button onClick={(e) => setTogglePasswordChange(true)}>Change Password</Button>
              </Col>
            )
          }
        </Row>

      </Card>
    </div>
  )
}

export default MyProfile;