import React, { useEffect, useReducer, useState } from 'react';
import { ConfigProvider, Spin, theme } from 'antd';

import 'antd/dist/reset.css';
import './App.css';

import AdminModule from './components/AdminModule';
import Login from './components/Login';
import { Context, initialState, reducer } from './components/Reducer';
import { Theme } from './components/Theme';
import { LOGIN } from './components/Reducer/types';
import Loading from './components/Loading';

function App() {
  const [signedIn, setSignedIn] = useState(false);
  const [token, setToken] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [superAdmin, setSuperAdmin] = useState(false);
  const [initialTab, setInitialTab] = useState('login');
  const [loading, setLoading] = useState(true);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log('App State - ', state);

    const sessionStorageData = sessionStorage.getItem('u');
    let newData;
    if (sessionStorageData) {
      setLoading(false);
      newData = JSON.parse(sessionStorageData);
      dispatch({
        type: LOGIN,
        payload: {
          /* isAuthenticated: true,
          page: newData.page, */
          ...newData,
        },
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <ConfigProvider theme={Theme}>
      {loading ? (
        <Loading full={true} />
      ) : state && 'isAuthenticated' in state && state.isAuthenticated ? (
        <Context.Provider value={[state, dispatch]}>
          <AdminModule />
        </Context.Provider>
      ) : (
        <Context.Provider value={[state, dispatch]}>
          <Login />
        </Context.Provider>
      )}
    </ConfigProvider>
  );
}

export default App;
