import { green } from "@ant-design/colors";
import { Button, Card, Col, Row, Typography, secondary, Input, Table, notification } from "antd";
import { useContext, useEffect, useState } from "react";

import { Context } from "../Reducer";
import { PAGE } from "../Reducer/types";
import Loading from '../Loading';
import NoData from "../NoData";
import { Get, url } from "../Utils";


const VideoAssets = () =>
{
  const [state, dispatch] = useContext(Context);
  const { Title, Text } = Typography;

  const [data, setData] = useState("");
  const [allData, setAllData] = useState("");

  const getVideosList = () =>
  {
    Get('/videos', state.token, '').then((res) =>
    {
      console.log('Get Videos - ', res);
      setData(res.data.videos);
      setAllData(res.data.videos);
    }).catch(async (err) =>
    {
      let errData = await err.json();
      notification.error({
        message: `Code: ${err.status}`,
        description: (
          <>
            <div><strong>{`${errData.detail}!`}</strong></div>
            <div>Please check console for more details.</div>
          </>
        )
      });
      console.log('List of Videos err - ', await err.json());
      setData("NoData");
      setAllData("NoData");
    })
    /* fetch(url + '/videos').then((res) => res.json()).then((res) =>
    {
      setData(res.data.videos);
      setAllData(res.data.videos);
      console.log('Videos data - ', res.data.videos);
    }).catch((err) =>
    {
      console.log('Err - ', err);
      setData("NoData");
      setAllData("NoData");
    }) */

  }

  /* const dataSource = [
    {
      key: '1',
      id: 'DFE367834X3',
      thumbnail: 'https://images.unsplash.com/photo-1497015289639-54688650d173?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=100&q=80',
      duration: '0:10',
      status: 'Ready',
      created: '02/27/23 12:47 pm'
    },
    {
      key: '2',
      id: 'E4RTY7834X3',
      thumbnail: 'https://images.unsplash.com/photo-1543242594-c8bae8b9e708?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=100&q=80',
      duration: '0:10',
      status: 'Ready',
      created: '02/27/23 12:47 pm'
    },
  ]; */

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      render: (_, data) => (
        <Button type="link" onClick={(e) => goToDetail(e, data._id)}>
          <Row align={'middle'}>
            {
              data.thumbnail !== 'NA' ? (
                <Col style={{ marginRight: '25px', maxHeight: '50px', maxWidth: '80px', overflow: 'hidden' }}>
                  <img src={data.thumbnail} style={{ width: '80px' }} />
                </Col>
              ) : ""
            }

            <Col>
              {data._id}
            </Col>
          </Row>
        </Button>
      )
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, data) =>
      (
        data.status === 'ready' ? (
          <Text style={{
            color: green[6]
          }}>{data.status}</Text>
        ) : (
          <Text>{data.status}</Text>
        )
      )
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
    },
  ];

  // Create New Video Asset
  const createNewVideoAsset = (e) =>
  {
    e.preventDefault();
    dispatch({
      type: PAGE,
      payload: {
        page: 'createVideoAsset'
      }
    })
  }

  const goToDetail = (e, id) =>
  {
    e.preventDefault();
    console.log('Video Detail id - ', id);
    dispatch({
      type: PAGE,
      payload: {
        page: 'videoAssetsDetail',
        videoDetailId: id
      }
    })
  }

  //  Filter ID
  const filterId = (e) =>
  {

    if (allData)
    {
      if (e.target.value === '')
      {
        setData(allData);
      } else
      {
        const filteredData = allData.filter((itm) => itm._id.includes(e.target.value));
        if (filteredData.length > 0)
        {
          setData(filteredData);
        } else
        {
          setData([]);
        }
      }
    }
  }

  useEffect(() =>
  {

    //  Get Videos List
    getVideosList();

    /* return () =>
    {

    } */
  }, [])



  return (
    <div className="main-content">
      <Card>
        <Row>
          <Col span={12}>
            <Title level={2}>Video Assets</Title>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={(e) => createNewVideoAsset(e)}>
              Create New Asset
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text code className="code">
              <span style={{ marginRight: '5px' }}>GET</span>
              <span>/video/v1/assets</span>
            </Text>
          </Col>
        </Row>
        {
          allData === "" ? (
            <div style={{ height: '400px', width: '100%' }}>
              <Loading />
            </div>
          ) : allData && allData.length > 0 ? (
            <>
              <Row style={{ marginTop: '50px' }}>
                <Col span={24}>
                  <Input.Group compact>
                    <Input size="large" prefix="GET /video/v1/assets/" style={{ width: 'calc(100% - 200px)' }} defaultValue="" placeholder="{enter id here}" onChange={filterId} />
                    <Button size="large" style={{ width: '200px' }} >GET</Button>
                  </Input.Group>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ marginTop: '25px' }}>
                  <Table dataSource={data} columns={columns} pagination={{ pageSize: 100 }} />
                </Col>
              </Row>
            </>
          ) : allData === "NoData" ? (
            <NoData />
          ) : ""
        }

      </Card>
    </div>
  )
}

export default VideoAssets;