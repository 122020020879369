import { createContext } from "react";
import { LOGIN, LOGOUT, PAGE } from "./types"

export const Context = createContext()


export const initialState = {
  /* isAuthenticated: false, */
  token: null,
  apiUrl: null,
  page: 'login',
  _id: ''
};

/* export const url = 'https://api.fastpix.io'; */

export const reducer = (state = initialState, action) =>
{
  switch (action.type)
  {
    case LOGIN:
      const userInfo = {
        isAuthenticated: true,
        page: 'videoAssets',
        token: action.payload.token,
      }
      sessionStorage.setItem('u', JSON.stringify(userInfo));
      console.log('LOGIN - ', userInfo);
      return {
        ...state,
        ...userInfo,
        isAuthenticated: action.payload.isAuthenticated,
        page: action.payload.page,
        token: action.payload.token,
      }

    case LOGOUT:
      sessionStorage.removeItem('u');
      return {
        isAuthenticated: false,
        token: null,
        apiUrl: null,
        page: 'login',
        _id: ''
      }

    case PAGE:
      return {
        ...state,
        ...action.payload,
        page: action.payload.page
      }

    default:
      break;
  }
}