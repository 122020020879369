import { Button, Card, Col, Collapse, Descriptions, Divider, message, Modal, notification, Row, Space, Typography, } from "antd";
import { CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, SoundOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { blue, gray, green, orange, red } from '@ant-design/colors'
import { useContext, useEffect, useRef, useState } from "react";


import Player from "../Player";
import './videoAssetsDetail.css'
import videojs from 'video.js';
import { Context } from '../Reducer';
import { PAGE } from "../Reducer/types";
import { Get, url } from "../Utils";

const VideoAssetDetail = () =>
{

  const { Title, Text, Paragraph } = Typography;
  const { Panel } = Collapse;
  const [togglePlayerCodeModal, setTogglePlayerCodeModal] = useState(false);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [data, setData] = useState(null);

  const [modal, contextHolder] = Modal.useModal();

  const [state, dispatch] = useContext(Context);

  const videoData = {
    _id: 'VA5auJ7xHIz024edVD7AdfpBK00pohmGK4nEONqCl2qnY',
    created: '02/27/23 12:47 pm',
    status: 'ready',
    duration: '0:10',
    max_resolution: 'HD',
    max_frame_rate: 29.970,
    aspect_ratio: '16:9',
    thumbnail: 'https://image.fastpix.io/OMaHKSsXX4BAOrkfclju3u154bm25eIBI4BM0194KdEQ/thumbnail.png?width=214&height=121&fit_mode=pad',
    playback: [
      {
        "policy": "public",
        "id": "OMaHKSsXX4BAOrkfclju3u154bm25eIBI4BM0194KdEQ"
      }
    ],
    tracks: [
      {
        "type": "video",
        "max_width": 1920,
        "max_height": 1080,
        "max_frame_rate": 29.97,
        "id": "nsDkQCzJBOnUuBecvcmHXQGh4902kppUklbW01jMSPL7U",
        "duration": 23.8238
      },
      {
        "type": "audio",
        "max_channels": 2,
        "max_channel_layout": "stereo",
        "id": "3XQ4vCeWGoFS6smls1Nc4P3pUlhcX01b6FHMwjWLnxVY",
        "duration": 23.823792
      }
    ],
    inputFiles: [
      {
        "settings": {
          "url": "https://storage.azure.com/fastpixdemofiles/fastpix-video-intro.mp4"
        },
        "file": {
          "tracks": [
            {
              "width": 1920,
              "type": "video",
              "height": 1080,
              "frame_rate": 29.97,
              "encoding": "h264",
              "duration": 23.8238
            },
            {
              "type": "audio",
              "sample_rate": 48000,
              "encoding": "aac",
              "duration": 23.823792,
              "channels": 2
            }
          ],
          "container_format": "mov,mp4,m4a,3gp,3g2,mj2"
        }
      },
      {
        "settings": {
          "url": "https://storage.azure.com/fastpixdemofiles/fastpix-test-video-watermark.png",
          "overlay_settings": {
            "width": "640px",
            "vertical_margin": "100px",
            "vertical_align": "bottom",
            "opacity": "100.000000%",
            "horizontal_align": "center"
          }
        },
        "file": {
          "tracks": [
            {
              "width": 642,
              "type": "image",
              "height": 346,
              "encoding": "png"
            }
          ],
          "container_format": "png"
        }
      }
    ],
  }

  const playerRef = useRef(null);

  const playerOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
  }


  const handlePlayerReady = (player) =>
  {
    playerRef.current = player;

    player.on('waiting', () =>
    {
      videojs.log('player is waiting');
    });

    player.on('dispose', () =>
    {
      videojs.log('player will dispose');
    });
  }

  const copyToClipboard = (e, text) =>
  {
    //  e.preventDefault();
    // Copy the text inside the text field
    message.success('Copied to Clipboard');
    navigator.clipboard.writeText(text);

  }

  //  Delete Modal OnOK
  const deleteModalOk = () =>
  {
    setDeleteModalToggle(false);
  }

  //  Delete Modal OnCancel
  const deleteModalCancel = () =>
  {
    setDeleteModalToggle(false);
  }

  //  Get Video Details
  const getVideoDetails = (id) =>
  {
    Get(`/videos/${id}`, state.token).then((res) =>
    {

      if (res.success)
      {
        setData(res.data.video);
      } else
      {
        console.log('Video Detail res - ', res);
        dispatch({
          type: PAGE,
          payload: {
            page: 'videoAssets',
            videoDetailId: null,
          }
        });
        notification.error({
          message: `Video ID is wrong!`,
          description: 'Please check console for details!'
        });
      }
    }).catch(async (err) =>
    {
      let errData = await err.json();
      notification.error({
        message: `Code: ${err.status}`,
        description: (
          <>
            <div><strong>{`${errData.detail}!`}</strong></div>
            <div>Please check console for more details.</div>
          </>
        )
      });

      dispatch({
        type: PAGE,
        payload: {
          page: 'videoAssets',
          videoDetailId: null,
        }
      });

    })
    /* fetch(url + '/videos/' + id).then((res) => res.json()).then((res) =>
    {
      console.log('VideoDetail res - ', res);
      if (res.success)
      {
        setData(res.data.video);
      } else
      {
        dispatch({
          type: PAGE,
          payload: {
            page: 'videoAssets',
            videoDetailId: null,
          }
        });
        message.error('Something went wrong');
      }
    }).catch((err) =>
    {
      dispatch({
        type: PAGE,
        payload: {
          page: 'videoAssets',
          videoDetailId: null,
        }
      });
      message.error('Something went wrong');
    }) */
  }

  useEffect(() =>
  {
    getVideoDetails(state.videoDetailId);
    /* if (state && 'videoDetailId' in state && state.videoDetailId)
    {
    } else
    {
      dispatch({
        type: PAGE,
        payload: {
          page: 'videoAssets'
        }
      });
      message.error('Something went wrong');
    } */

    return () =>
    {
      setTogglePlayerCodeModal(false);
      /* dispatch({
        type: PAGE,
        payload: {
          page: 'videoAssets',
          videoDetailId: null,
        }
      }) */
    }
  }, [])


  return (
    <>
      {
        togglePlayerCodeModal ? (
          /* Right Sidebar */
          <div className={`playerModal ${togglePlayerCodeModal ? `show` : ``}`}>
            <Row align={'middle'} justify="center">
              <Col span={16}>
                <Row>
                  <Col span={24}>
                    <Title level={4}>
                      Integrate playback with FastPix Player
                    </Title>
                    <Text style={{ marginTop: '20px' }}>
                      Use Fastpix Player to quickly integrate into your app. <a href="#">See our docs</a> to learn more about how you can customize and format FastPix Player.
                    </Text></Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col span={24}>
                    <div className="copyCodeBlock">
                      <code>
                        <Button type="link" className="copyButtonFloating" onClick={(e) => copyToClipboard(e, `<script src="https://cdn.fastpix.io/fastpix-player"></script>
                    <fastpix-player
                      stream-type="on-demand"
                      playback-id="89DW90D0W90SKDOKGOW900"
                      metadata-video-title="Placeholder (optional)"
                      metadata-viewer-user-id="Placeholder (optional)"
                      primary-color="#FFFFFF"
                      secondary-color="#000000">
                    </fastpix-player>`)}><CopyOutlined /></Button>
                        {`
                    <script src="https://cdn.fastpix.io/fastpix-player"></script>
                    <fastpix-player
                      stream-type="on-demand"
                      playback-id="89DW90D0W90SKDOKGOW900"
                      metadata-video-title="Placeholder (optional)"
                      metadata-viewer-user-id="Placeholder (optional)"
                      primary-color="#FFFFFF"
                      secondary-color="#000000">
                    </fastpix-player>
                    `}
                      </code>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col span={24}>
                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', aspectRatio: '16/9', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Player options={{
                        ...playerOptions, sources: [{
                          src: data.playback_id,
                          type: 'application/x-mpegURL'
                        }]
                      }} onReady={handlePlayerReady} />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col span={24}>
                    <Button className="w-100" size="large" onClick={(e) => setTogglePlayerCodeModal(false)}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>) : ""
      }

      {
        data ? (
          <div className="main-content">
            <Card>
              {/* Header */}
              <Row>
                <Col span={12}>
                  <Title level={2}>Video Assets</Title>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button type="" style={{ backgroundColor: red[6], color: 'white' }} icon={<DeleteOutlined />} onClick={() => setDeleteModalToggle(true)}>
                    Delete
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {
                    data ? (
                      <Text code className="code">
                        <span style={{ marginRight: '5px' }}>GET</span>
                        <span>/video/v1/assets/{data._id}</span>
                      </Text>
                    ) : (
                      <Text code className="code">
                        <span style={{ marginRight: '5px' }}>GET</span>
                        <span>/video/v1/assets/{videoData._id}</span>
                      </Text>
                    )
                  }

                </Col>
              </Row>

              {/* Player and Info */}
              <Row align={"stretch"} style={{ marginTop: '40px', border: '1px solid #ddd', borderRadius: '3px', backgroundColor: 'rgba(0,0,0,0.05)' }}>
                <Col span={10} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Player options={{
                    ...playerOptions, sources: [{
                      src: data.playback_id,
                      type: 'application/x-mpegURL'
                    }]
                  }} onReady={handlePlayerReady} />
                </Col>
                <Col span={14} style={{ padding: '25px' }}>
                  <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row>
                      <Col span={8}>
                        <Text italic>Asset ID</Text>
                      </Col>
                      <Col span={16} style={{ display: 'flex', }}>
                        <div style={{ width: 'calc(100% - 50px)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                          {
                            data ? data._id : videoData._id
                          }
                        </div>
                        <Button type="ghost" style={{ marginTop: '-3px', color: blue.primary }} onClick={(e) => copyToClipboard(e, `${videoData._id}`)}>
                          <CopyOutlined />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Text italic>Created</Text>
                      </Col>
                      <Col span={16}>
                        {
                          data ? data.created_at :
                            videoData.created
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Text italic>Status</Text>
                      </Col>
                      <Col span={16}>
                        {data.status === 'ready' ? <Text style={{ color: green[6] }}>{data.status}</Text> : <Text>{data.status}</Text>}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Text italic>Duration</Text>
                      </Col>
                      <Col span={16}>
                        {
                          data ? data.duration :
                            videoData.duration
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Text italic>Max Resolution</Text>
                      </Col>
                      <Col span={16}>
                        {
                          data ? data.max_resolution :
                            videoData.max_resolution
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Text italic>Max Frame Rate</Text>
                      </Col>
                      <Col span={16}>
                        {
                          data ? data.max_frame_rate :
                            videoData.max_frame_rate
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Text italic>Aspect Ratio</Text>
                      </Col>
                      <Col span={16}>
                        {
                          data ? data.aspect_ratio :
                            videoData.aspect_ratio
                        }
                      </Col>
                    </Row>
                  </Space>
                </Col>
              </Row>

              <Row style={{ marginTop: '30px' }} gutter={30}>
                {/* LeftSidebar */}
                <Col span={14}>
                  {/* Playback IDs */}
                  <Row className="detailBlock">
                    <Col span={24}>
                      <Row className="detailBlockHeader">
                        <Col span={24}>
                          <Title level={5} style={{ fontWeight: 'normal' }}>Playback IDs</Title>
                        </Col>
                        <Col span={24}>
                          <Paragraph>
                            Playback IDs are generated for Assets, Live Streams, and other playable resources. Use them to create a stream.fastpix.io URL, which can be used as the source for a video player.
                          </Paragraph>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="detailBlockCode">
                          <Collapse>
                            <Panel header="Show response details" key="1">
                              <pre>
                                <code>
                                  {`[ { "policy": "public","id": "OMaHKSsXX4BAOrkfclju3u154bm25eIBI4BM0194KdEQ" } ]`}
                                </code>
                              </pre>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="detailBlockTable">
                          {
                            videoData.playback.length > 0 ? videoData.playback.map((data, ind) => (
                              <Row key={ind + 'playbackid'}>
                                <Col span={2}>
                                  <code>{ind}</code>
                                </Col>
                                <Col span={6}>
                                  <code>{data.policy}</code>
                                </Col>
                                <Col span={14}>
                                  <Text ellipsis><code>{data.id}</code></Text>
                                </Col>
                                <Col span={2}>
                                  <Button type="ghost" style={{ marginTop: '-3px', color: blue.primary }} onClick={(e) => copyToClipboard(e, `${data.id}`)}>
                                    <CopyOutlined />
                                  </Button>
                                </Col>
                              </Row>
                            ))
                              : ""
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* Tracks */}
                  <Row className="detailBlock">
                    <Col span={24}>
                      <Row className="detailBlockHeader">
                        <Col span={24}>
                          <Title level={5} style={{ fontWeight: 'normal' }}>Tracks</Title>
                        </Col>
                        <Col span={24}>
                          <Paragraph>
                            Tracks are different representations of an asset's content (audio, video, text).
                          </Paragraph>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="detailBlockCode">
                          <Collapse>
                            <Panel header="Show response details" key="1">
                              <code>
                                {`[ { "policy": "public","id": "OMaHKSsXX4BAOrkfclju3u154bm25eIBI4BM0194KdEQ" } ]`}
                              </code>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="detailBlockTable">
                          {/* expandIconPosition="right"  */}
                          <Collapse expandIconPosition="end" className="detailBlockTableCollapse">
                            {
                              videoData.tracks.length > 0 ? videoData.tracks.map((data, ind) => (
                                <>
                                  {
                                    data.type === 'video' ? (
                                      <Panel header={
                                        <Row key={ind + 'playbackid'}>
                                          <Col span={2}>
                                            <code>{ind}</code>
                                          </Col>

                                          <Col span={8}>
                                            <Text>
                                              <VideoCameraOutlined /> video
                                            </Text>
                                          </Col>
                                          <Col span={14}>
                                            <Text><code>{data.max_height}</code></Text>
                                          </Col>
                                        </Row>
                                      } key={ind + 'vdo'}>
                                        <Row style={{ backgroundColor: 'rgba(0,0,0,0.07)', padding: '10px' }}>
                                          <Col span={24}>
                                            <code>
                                              {
                                                `{
                                            "type": "video",
                                            "max_width": 1920,
                                            "max_height": 1080,
                                            "max_frame_rate": 29.97,
                                            "id": "nsDkQCzJBOnUuBecvcmHXQGh4902kppUklbW01jMSPL7U",
                                            "duration": 23.8238
                                          }`
                                              }
                                            </code>
                                          </Col>
                                        </Row>
                                      </Panel>
                                    ) : ""
                                  }
                                  {
                                    data.type === 'audio' ? (
                                      <Panel header={
                                        <Row key={ind + 'playbackid'}>
                                          <Col span={2}>
                                            <code>{ind}</code>
                                          </Col>

                                          <Col span={8}>
                                            <Text>
                                              <SoundOutlined /> audio
                                            </Text>
                                          </Col>
                                          <Col span={14}>
                                            <Text><code>{data.max_channel_layout}</code></Text>
                                          </Col>
                                        </Row>
                                      } key={ind + 'ado'}>
                                        <Row key={ind + 'playbackid'} style={{ backgroundColor: 'rgba(0,0,0,0.07)', padding: '10px' }}>
                                          <Col span={24}>
                                            <code>
                                              {
                                                `{
                                            "type": "video",
                                            "max_width": 1920,
                                            "max_height": 1080,
                                            "max_frame_rate": 29.97,
                                            "id": "nsDkQCzJBOnUuBecvcmHXQGh4902kppUklbW01jMSPL7U",
                                            "duration": 23.8238
                                          }`
                                              }
                                            </code>
                                          </Col>
                                        </Row>
                                      </Panel>
                                    ) : ""
                                  }

                                </>

                              ))
                                : ""
                            }
                          </Collapse>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* Input Files */}
                  <Row className="detailBlock">
                    <Col span={24}>
                      <Row className="detailBlockHeader">
                        <Col span={24}>
                          <Title level={5} style={{ fontWeight: 'normal' }}>Input Files</Title>
                        </Col>
                        <Col span={24}>
                          <Paragraph>
                            Input files include the details of the files or other fastpix assets that were used to create the asset.
                          </Paragraph>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="detailBlockCode">
                          <Collapse>
                            <Panel header="Show response details" key="1">
                              <pre>
                                <code>
                                  {`[ { "policy": "public","id": "OMaHKSsXX4BAOrkfclju3u154bm25eIBI4BM0194KdEQ" } ]`}
                                </code>
                              </pre>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="detailBlockTable">
                          <Collapse className="detailBlockTableCollapse" expandIconPosition="end">
                            {
                              videoData.inputFiles.length > 0 ? videoData.inputFiles.map((data, ind) => (
                                <>
                                  <Panel header={
                                    <Row style={{}}>
                                      <Col span={2}>
                                        <code>{ind}</code>
                                      </Col>

                                      <Col span={20}>
                                        <Text code ellipsis>
                                          {data.settings.url.substring(0, 50)}...
                                        </Text>
                                      </Col>

                                    </Row>
                                  } key={ind + 'inputfiles'}>
                                    <Row key={ind + 'playbackid'} style={{ backgroundColor: 'rgba(0,0,0,0.07)', padding: '10px' }}>
                                      <Col span={24}>
                                        <code>
                                          {
                                            `{
                                        "settings": {
                                          "url": "https://storage.azure.com/fastpixdemofiles/fastpix-video-intro.mp4"
                                        },
                                        "file": {
                                          "tracks": [
                                            {
                                              "width": 1920,
                                              "type": "video",
                                              "height": 1080,
                                              "frame_rate": 29.97,
                                              "encoding": "h264",
                                              "duration": 23.8238
                                            },
                                            {
                                              "type": "audio",
                                              "sample_rate": 48000,
                                              "encoding": "aac",
                                              "duration": 23.823792,
                                              "channels": 2
                                            }
                                          ],
                                          "container_format": "mov,mp4,m4a,3gp,3g2,mj2"
                                        }
                                      }`
                                          }
                                        </code>
                                      </Col>
                                    </Row>
                                  </Panel>
                                </>

                              ))
                                : ""
                            }
                          </Collapse>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* Recent Events */}
                  <Row className="detailBlock">
                    <Col span={24}>
                      <Row className="detailBlockHeader">
                        <Col span={24}>
                          <Title level={5} style={{ fontWeight: 'normal' }}>Recent Events</Title>
                        </Col>
                        <Col span={24}>
                          <Row align={'bottom'}>
                            <Col span={16}>
                              <Paragraph>
                                Event capture any activity related to this assets.
                              </Paragraph>
                            </Col>
                            <Col span={8}>
                              <Button type="link">View all event history</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Row style={{ borderBottom: '1px solid #eee', padding: '5px 15px' }}>
                            <Col span={12}>
                              <code>video.asset.<Text style={{ color: green[5] }}>ready</Text></code>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                              <Text>02/27/23 12:47:36 pm</Text>
                            </Col>
                          </Row>
                          <Row style={{ borderBottom: '1px solid #eee', padding: '5px 15px' }}>
                            <Col span={12}>
                              <code>video.asset.<Text style={{ color: green[5] }}>created</Text></code>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                              <Text>02/27/23 12:47:36 pm</Text>
                            </Col>
                          </Row>

                        </Col>
                      </Row>


                    </Col>
                  </Row>

                  {/* Logs */}
                  <Row className="detailBlock">
                    <Col span={24}>
                      <Row className="detailBlockHeader">
                        <Col span={24}>
                          <Title level={5} style={{ fontWeight: 'normal' }}>Logs</Title>
                        </Col>
                        <Col span={24}>
                          <Row align={'bottom'}>
                            <Col span={16}>
                              <Paragraph>
                                Logs show any API activity specific to this asset.
                              </Paragraph>
                            </Col>
                            <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-end' }}>
                              <Button type="link">View all log history</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Row style={{ borderBottom: '1px solid #eee', padding: '5px 15px' }}>
                            <Col span={3}>
                              <code>201</code>
                            </Col>
                            <Col span={13}>
                              <code>POST https://api.fastpix.io/video/v1/assets</code>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <Text>02/27/23 12:47:36 pm</Text>
                            </Col>
                          </Row>
                          <Row style={{ borderBottom: '1px solid #eee', padding: '5px 15px' }}>
                            <Col span={3}>
                              <code>201</code>
                            </Col>
                            <Col span={13}>
                              <code>POST https://api.fastpix.io/audio/v1/assets</code>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <Text>02/27/23 12:47:36 pm</Text>
                            </Col>
                          </Row>

                        </Col>
                      </Row>


                    </Col>
                  </Row>
                </Col>
                <Col span={1}></Col>
                {/* RightSidebar */}
                <Col span={9}>
                  {/* Playback your video */}
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={16}>
                          <Text strong style={{ fontSize: '16px' }}>Playback Your Video</Text>
                        </Col>
                        <Col span={8}>
                          <Button size="small" type="link">Playback guide</Button>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: '15px' }}>
                        <Col span={24}>
                          Integrate into your app with FastPix Player, or read our <a href="#">Playback Guide</a> on embedding any other player.
                        </Col>
                        <Col span={24} style={{ paddingTop: '20px' }}>
                          <Button type="primary" style={{ width: '100%' }} size="large" onClick={(e) => setTogglePlayerCodeModal(true)}>
                            Integrate now with FastPix Player
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* Thumbnail Samples */}
                  <Row style={{ marginTop: '30px' }}>
                    <Col span={24}>
                      <Row>
                        <Col span={16}>
                          <Text strong style={{ fontSize: '16px' }}>Thumbnail Samples</Text>
                        </Col>
                        <Col span={8}>
                          <Button size="small" type="link">Thumbnail guide</Button>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: '15px' }}>
                        <Col span={24} style={{ backgroundColor: '#eee', padding: '10px', borderRadius: '3px' }}>
                          <code style={{ wordBreak: 'break-all' }}>
                            GET https://image.fastpix.io/OMaHKSsXX4BAOrkfclju3u154bm25eIBI4BM0194KdEQ/thumbnail.png?width=214&height=121&fit_mode=pad
                          </code>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '30px' }}>
                        <Col span={24}>
                          <div style={{ width: '90%', position: 'relative' }}>
                            <img src={data ? data.thumbnail : '/images/FastPix_Logo.svg'} alt="" style={{ /* aspectRatio: '16/9', */  width: '100%', objectFit: 'contain', position: 'relative', zIndex: '4' }} />
                            {/* <span style={{ position: 'absolute', bottom: 0, left: 0, zIndex: '6', color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', padding: '4px 10px', fontSize: '13px', lineHeight: '1' }}>314x178 @0:01</span> */}
                          </div>
                        </Col>
                        <Col span={24} style={{ marginTop: '30px' }}>
                          <div style={{ width: '60%', position: 'relative', overflow: 'hidden' }}>
                            <img src={data ? data.thumbnail : '/images/FastPix_Logo.svg'} alt="" style={{ /* aspectRatio: '16/9', */  width: '100%', objectFit: 'contain', position: 'relative', zIndex: '4' }} />
                            {/* <span style={{ position: 'absolute', bottom: 0, left: 0, zIndex: '6', color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', padding: '4px 10px', fontSize: '13px', lineHeight: '1' }}>214x121 @0:01</span> */}
                          </div>
                        </Col>
                        <Col span={24} style={{ marginTop: '30px' }}>
                          <div style={{ width: '40%', position: 'relative', objectFit: 'contain', }}>
                            <img src={data ? data.thumbnail : '/images/FastPix_Logo.svg'} alt="" style={{ /* aspectRatio: '16/9', */  width: '100%', position: 'relative', zIndex: '4' }} />
                            {/* <span style={{ position: 'absolute', bottom: 0, left: 0, zIndex: '6', color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', padding: '4px 10px', fontSize: '13px', lineHeight: '1' }}>140x64 @0:01</span> */}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </div>
        ) : ""
      }
      <Modal title={
        <>
          <span style={{ color: red.primary, marginRight: '10px' }}>
            <ExclamationCircleOutlined />
          </span>
          <span style={{ color: red.primary }}>
            Are you sure?
          </span>
        </>
      } open={deleteModalToggle} closable={false} footer={[
        <Button
          onClick={deleteModalCancel} size="large"
        >
          Cancel
        </Button>,
        <Button key="submit" type="default" style={{ backgroundColor: red.primary, color: 'white', paddingLeft: '30px', paddingRight: '30px' }} onClick={deleteModalOk} size="large">
          Delete
        </Button>,
      ]}>
        <p>This asset and all associated playback IDs will be permanently deleted. This action cannot be undone</p>
        <code>
          DELETE /video/v1/assets/4KhD5aioZVeAXynLmuTJB2R1DZHpHaRRFjSnRMHabJc
        </code>
        <p>&nbsp;</p>
      </Modal>

    </>
  )
}

export default VideoAssetDetail;
