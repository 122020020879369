import { Button, Layout, Menu } from 'antd';
import React, { useContext, useState } from 'react'
import { Context } from '../Reducer';
import { LOGOUT, PAGE } from '../Reducer/types';

const Sidebar = () =>
{

  const [state, dispatch] = useContext(Context);

  const { Sider } = Layout;

  const [collapsed, setCollapsed] = useState(false);

  function getItem(label, key, icon, children)
  {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const items = [
    getItem('Dashboard', 'dashboard', ''),
    getItem('Content', 'content', '', [
      getItem('Video Assets', 'videoAssets'),
      getItem('Audio Assets', 'audioAssets'),
      getItem('Image Assets', 'imageAssets'),
      getItem('Live Streams', 'videoLiveStreams'),
    ]),
    getItem('Analytics', 'analytics', '', [
      getItem('Overview', 'analyticsOverview'),
      getItem('Metrics', 'analyticsMetrics'),
      getItem('Errors', 'analyticsErrors'),
      getItem('Views', 'analyticsViews'),
      getItem('Alerts', 'analyticsAlerts'),
    ]),
    getItem('Account', 'myAccount', '', [
      getItem('API Reports', 'myAccountApiReports'),
      getItem('Settings', 'myAccountSettings'),
      getItem('Docs', 'myAccountDocs'),
      getItem('My Profile', 'myAccountMyProfile'),
      getItem('Logout', 'logout'),
    ]),
  ];

  const menuOnClick = (e) =>
  {
    if (e.key !== 'logout')
    {
      dispatch({
        type: PAGE,
        payload: {
          page: e.key
        }
      })
    }

    if (e.key === 'logout')
    {
      dispatch({
        type: LOGOUT
      })
    }

  }



  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme="light">
      <div style={{ widtth: '100%', margin: '15px 0' }}>
        <Button type='link' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="/images/FastPix_Logo.svg" alt='FastPix' style={{ maxHeight: '50px', display: 'block' }} />
        </Button>
      </div>
      <Menu theme="light" onClick={menuOnClick} multiple={false} defaultSelectedKeys={['videoAssets']} selectedKeys={[state.page]} mode="inline" items={items} inlineCollapsed={false} />
    </Sider>
  )
}

export default Sidebar