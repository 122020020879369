import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, List, message, Modal, Progress, Row, Switch, Typography, Upload } from 'antd';
import { CloudUploadOutlined, LeftOutlined } from "@ant-design/icons";
import { Context } from '../Reducer'
import "./createVideoAsset.css";
import { PAGE } from '../Reducer/types';
import { blue, gray, green } from '@ant-design/colors';


const CreateVideoAsset = () =>
{

    const [data, setData] = useState([]);
    const [uploadPercentage, setUploadPercentage] = useState(null)
    const [toggleUpload, setToggleUpload] = useState({
        status: false,
    });

    let successMessage;

    const inputRef = useRef(null);



    const { Title, Text } = Typography;

    const [state, dispatch] = useContext(Context);

    const { Dragger } = Upload;

    const goToVideoAssets = (e) =>
    {
        e.preventDefault();
        dispatch({
            type: PAGE,
            payload: {
                page: 'videoAssets'
            }
        })
    }

    const tusUpload = (file) =>
    {
        // Create a new tus upload
        const upload = new window.tus.Upload(file, {
            // Endpoint is the upload creation URL from your tus server
            endpoint: "https://transcode.ibee.ai/fastpix/files",
            // Retry delays will enable tus-js-client to automatically retry on errors
            retryDelays: [0, 3000, 5000, 10000, 20000],
            // Attach additional meta data about the file for the server
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            // Callback for errors which cannot be fixed using retries
            onError: function (error)
            {
                console.log("Failed because: " + error)
            },
            // Callback for reporting upload progress
            onProgress: function (bytesUploaded, bytesTotal)
            {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                console.log(bytesUploaded, bytesTotal, percentage + "%");
                setUploadPercentage(percentage);
            },
            // Callback for once the upload is completed
            onSuccess: function ()
            {
                console.log("Download %s from %s", upload.file.name, upload.url);
                message.open({
                    type: 'success',
                    content: `${upload.file.name} video is uploaded successfully. In next 3 seconds you will be redirected to list page.`,
                    duration: 3
                })
                successMessage = setTimeout(() =>
                {
                    dispatch({
                        type: PAGE,
                        payload: {
                            page: 'videoAssets',
                        }
                    })
                }, 3000);
            },
            headers: {
                'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.5mhBHqs5_DTLdINd9p5m7ZJ6XD0Xc55kIaCRY5r6HRA",
                //'X-API-Client-Secret': "testSecret"
            }
        })

        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then(function (previousUploads)
        {
            // Found previous uploads so we select the first one.
            if (previousUploads.length)
            {
                upload.resumeFromPreviousUpload(previousUploads[0])
            }

            // Start the upload
            upload.start()
        })
    }

    //  Drag and Drop Upload
    const props = {
        name: 'file',
        multiple: false,
        //  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        customRequest({ file, onSuccess })
        {
            tusUpload(file);
            onSuccess("ok");
        },
        //         beforeUpload(file) {
        //             const reader = new FileReader();

        //             reader.onload = e => {
        //                 console.log(e.target.result);
        //                 tusUpload(e.target.result);
        //             };
        //             reader.readAsDataURL(file);
        //             // Prevent upload
        //             return false;
        //         },
        onChange(info)
        {
            //  const file = e.target.files[0]
            const file = info.file
            //  tusUpload(file);
            console.log('info - ', info);


        },
        onDrop(e)
        {
            console.log('Dropped files', e.dataTransfer.files);
            //  tusUpload(e.dataTransfer.files[0]);
        },
    };

    //  Toggle Upload Video or API Upload
    const toggleUploadApi = (e) =>
    {
        setToggleUpload({
            status: e
        })
    }

    useEffect(() =>
    {


        return () =>
        {
            clearTimeout(successMessage);
        }
    }, [])



    return (
        <div className="uploadVideoAsset">
            <Row>
                <Col span={24} className="uploadVideoAssetHeader">
                    <div className="uploadVideoAssetContent main-content">
                        <Button type='link' onClick={(e) => goToVideoAssets(e)} style={{ color: '#333', height: 'auto', fontSize: '15px', paddingLeft: '10px', paddingRight: '10px' }}>
                            <LeftOutlined />
                        </Button>
                        <img src="/images/FastPix_Logo.svg" alt="FastPix" style={{ height: '40px', display: 'inline-block' }} />
                    </div>
                </Col>
            </Row>
            <div className="main-content">
                <Row>
                    <Col offset={2} span={20}>
                        <Row style={{ marginTop: '20px', marginBottom: '30px' }}>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Title level={5}>
                                    Upload Video <Switch style={{ backgroundColor: toggleUpload.status ? `` : green.primary }} onChange={toggleUploadApi} /> Via API
                                </Title>
                            </Col>
                        </Row>
                        {
                            !toggleUpload.status ? (
                                <>
                                    <Row className='uploadBlock' style={{ marginTop: '20px' }}>
                                        <Col span={24}>
                                            <Dragger {...props}>
                                                <p style={{ fontSize: '110px', lineHeight: '1', color: blue.primary, marginTop: '40px', marginBottom: '20px', }}>
                                                    <CloudUploadOutlined />
                                                </p>
                                                <p className="ant-upload-text" style={{ fontSize: '20px', color: blue.primary }}>Click or drag file to this area to upload</p>
                                                <p className="ant-upload-hint">
                                                    Support file extensions only .mp4.
                                                </p>
                                            </Dragger>
                                        </Col>
                                    </Row>
                                    {
                                        uploadPercentage && uploadPercentage !== '100.00' ? (
                                            <Row>
                                                <Col span={24}>
                                                    <Progress percent={uploadPercentage} success={{ strokeColor: green[5] }} />
                                                </Col>
                                            </Row>
                                        ) : ""
                                    }
                                </>
                            ) : (
                                <Row style={{ marginTop: '20px' }}>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={24}>
                                                <img src="/images/api-upload.png" alt="" style={{ maxWidth: '100%' }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        }



                    </Col>
                </Row>

            </div>
        </div>

    )
};

export default CreateVideoAsset;
