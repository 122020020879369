import { useContext, useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Layout, message, notification, Row, Typography } from "antd";
import { Context } from '../Reducer'
import { LOGIN, PAGE } from "../Reducer/types";
import { url } from "../Utils";
import { Post } from "../Utils"
//  https://www.wrraptheme.com/templates/luno/auth-signin.html

const Login = () =>
{

  const { Title, Text } = Typography;
  const [loginForm] = Form.useForm();

  const [state, dispatch] = useContext(Context);

  const [loading, setLoading] = useState(false);

  //  const [state, dispatch] = useContext(Context);

  //  Login Now
  const loginNow = (values) =>
  {
    /* dispatch({
      type: LOGIN,
      payload: {
        isAuthenticated: true,
        page: 'dashboard'
      }
    }) */
    console.log('Values - ', values);

    setLoading(true);

    Post('/sessions', null, {
      "email": values.email,
      "password": values.password
    }).then((res) =>
    {
      if (res.success)
      {
        setLoading(false);
        dispatch({
          type: LOGIN,
          payload: {
            isAuthenticated: true,
            page: 'videoAssets',
            token: res.data.access_token,
          }
        })
      } else
      {
        message.error('Username or Password is wrong!')
      }
    }).catch((err) =>
    {
      console.log('Login err - ', err);
      notification.error({
        message: `Code: ${err.status}`,
        description: "Please check console for more details"
      })
    })

    /* fetch(url + '/sessions', {
      method: 'POST',
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "email": values.email,
        "password": values.password
      })
    }).then((res) => res.json()).then((res) =>
    {
      console.log('Res - ', res);
      if (res.success)
      {
        setLoading(false);
        dispatch({
          type: LOGIN,
          payload: {
            isAuthenticated: true,
            page: 'videoAssets',
            token: res.data.access_token,
          }
        })
      } else
      {
        message.error('Username or Password is wrong!')
      }
    }); */

  }

  useEffect(() =>
  {


    return () =>
    {
      setLoading(false);
    }
  }, [])





  return (
    <Layout color="light" className="padding2 loginBg">
      <Row align={'stretch'} style={{ minHeight: '100vh' }}>
        <Col span={16}>
        </Col>
        <Col span={8} style={{ alignItems: 'center', display: 'flex' }}>
          <Card
            style={{
              width: '100%',
            }}
            title={
              <Row style={{ padding: '20px 0', }}>
                <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src="/images/FastPix_Logo.svg" style={{ width: '50%' }} />
                </Col>
                <Col span={24} style={{ marginTop: '10px' }}>
                  <Title level={4} style={{ fontWeight: 'normal', textAlign: 'center' }}>
                    Signin
                  </Title>
                </Col>
              </Row>
            }
          >
            <Row>
              <Col span={24}>
                <Form
                  name="loginForm"
                  layout="vertical"
                  onFinish={loginNow}
                  //  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your email correctly!',
                        type: 'email'
                      }
                    ]}
                  >
                    <Input size="large" style={{ fontSize: '20px' }} />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please enter your password!' }]}
                  >
                    <Input.Password size="large" style={{ fontSize: '20px' }} />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{ marginTop: '30px' }}>
                    <Button type="primary" htmlType="submit" size="large">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default Login;