import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Popover, Row, Typography } from "antd"

const Settings = () =>
{

  const { Title, Text } = Typography


  return (
    <div className="main-content">
      <Card>
        <Row>
          <Col span={12}>
            <Title level={2}>Settings</Title>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>

          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text>Custom Dimensions allow you to track additional metadata beyond FastPix Data's standard dimensions. Learn more in our guide.</Text>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col span={24} className="table">
            <Row className="tableHeader">
              <Col span={4}>
                <Text>Visible</Text>
              </Col>
              <Col span={8}>
                <Text>Dimension</Text>
              </Col>
              <Col span={6}>
                <Text>Display Name</Text>
              </Col>
              <Col span={6}>
                <Text>Category <Popover content="Categories are used to group dimensions in Metrics and Views" trigger="click">
                  <Button type="link"><InfoCircleOutlined /></Button>
                </Popover></Text>
              </Col>
            </Row>
            <Row className="tableContent">
              <Col span={4}>
                <Checkbox disabled></Checkbox>
              </Col>
              <Col span={8}>
                <Text>Customer 1</Text>
              </Col>
              <Col span={6}>
                <Text>Custom 1</Text>
              </Col>
              <Col span={6}>
                <Text>Custom</Text>
              </Col>
            </Row>
          </Col>
        </Row>

      </Card>
    </div>
  )
}

export default Settings;