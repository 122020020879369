import { Layout, Menu } from "antd";
import { useContext, useEffect, useState } from "react";
import { Context } from "../Reducer";
import Sidebar from '../Sidebar'

/* Pages */
import Login from "../Login";
import Dashboard from "../Dashboard";
import VideoAssets from "../VideoAssets";
import VideoAssetDetail from "../VideoAssetDetail";
import Settings from "../Settings";
import MyProfile from "../MyProfile";
import CreateVideoAsset from "../CreateVideoAsset";


const AdminModule = () =>
{
  const { Header, Content, Footer, Sider } = Layout;

  const [state, dispatch] = useContext(Context);

  const [collapsed, setCollapsed] = useState(false);

  //  Show Page
  const pageRender = () =>
  {
    switch (state.page)
    {
      case 'login':
        return <Login />

      case 'dashboard':
        return <Dashboard />

      case 'videoAssets':
        return <VideoAssets />

      case 'videoAssetsDetail':
        return <VideoAssetDetail />

      case 'myAccountSettings':
        return <Settings />

      case 'myAccountMyProfile':
        return <MyProfile />

      case 'createVideoAsset':
        return <CreateVideoAsset />



      default:
        return "You do not have permissions to view this module"
    }
  }

  useEffect(() =>
  {
    pageRender();

    console.log('Dashboard State - ', state);

    return () =>
    {

    }
  }, [state.page])


  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar />
        <Layout className="site-layout">
          <Content className="padding-2">
            {pageRender()}
          </Content>
          <Footer style={{ textAlign: 'center' }}>Fast Pix &copy; 2023 | All Rights Reserved</Footer>
        </Layout>
      </Layout>
    </>
  )
}

export default AdminModule;