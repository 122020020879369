import { Card, Col, Row, Typography } from "antd";

const Dashboard = () => {

  const {Title, Text} = Typography

  return (
    <div className="main-content">
      <Card>
        <Row>
          <Col span={12}>
            <Title level={2}>Dashboard</Title>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>

          </Col>
        </Row>

      </Card>
    </div>
  )
}

export default Dashboard;