import { MehOutlined } from "@ant-design/icons";
import './nodata.css';

const NoData = () =>
{
  return (
    <div className="noData">
      <div className="noDataIcon">
        <MehOutlined />
      </div>
      <div className="noDataText">
        No Data Found
      </div>
    </div>
  )
}

export default NoData;