import { Spin } from "antd"
import "./loading.css"

const Loading = ({ full }) =>
{
  return (
    <div className={`loading ${full} ? 'full' : ''`}>
      <Spin size="large" />
    </div>
  )
}

export default Loading;