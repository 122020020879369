export const Theme = {
  token: {
    colorText: '#333',
    colorPrimary: '#645DD7',
    colorTextSecondary: '#E3371E',
    colorTextTertiary: 'A4969B',
    colorSuccess: '',
    colorError: '#E03616',
    fontFamily: "Work Sans, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    borderRadius: 3,
    fontSizeHeading1: 38,
    fontSizeHeading2: 32,
    fontSizeHeading3: 28,
    fontSizeHeading4: 26,
    fontSizeHeading5: 22,
    fontSizeHeading6: 18,
  },
  components: {

  },
}